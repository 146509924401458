<template>
  <div>
    <h2>Dataset Report</h2>
    <table>
      <tr>
        <th>Total Conversations</th>
        <th>Total Messages</th>
        <th>User Messages</th>
        <th>Worker Messages</th>
        <th>Average Conversation Length</th>
        <th>Average User Message Count / Conversation</th>
        <th>Average Poke Count / Conversation</th>
      </tr>
      <tr>
        <td>{{ total_convos }}</td>
        <td>{{ total_messages }}</td>
        <td>{{ user_messages }}</td>
        <td>{{ worker_messages }}</td>
        <td>{{ avg_conv_len }}</td>
        <td>{{ avg_user_msg_per_conv }}</td>
        <td>{{ avg_poke_count_per_conv }}</td>
      </tr>
    </table>
    <br>
    <div class="date-inputs">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" v-model="startDate">
      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" v-model="endDate">
      <button @click="downloadConversations">Download Conversations</button> <!-- Added this button -->
      <button @click="fetchConvosDPO">Download Conversations(DPO raw json)</button> <!-- Added this button -->
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      total_convos: "..Calculating..",
      total_messages: "..Calculating..",
      user_messages: "..Calculating..",
      worker_messages: "..Calculating..",
      avg_conv_len: "..Calculating..",
      avg_user_msg_per_conv: "..Calculating..",
      avg_poke_count_per_conv: "..Calculating..",
    };
  },
  created() {
    this.getToken();
    this.fetchData();
  },
  methods: {
    getToken(){
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async fetchData() {
      try {
          await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/dataset-stats/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
        }).then(response => response.json())
        .then(data => {
          const stats = data[0]
          this.total_convos = stats["Total Conversations"];
          this.total_messages = stats["Total Messages"];
          this.user_messages = stats["User Messages"];
          this.worker_messages = stats["Worker Messages"];
          this.avg_conv_len = stats["Average Conversation Length"];
          this.avg_user_msg_per_conv = stats["Average User Message Count per Conversation"];
          this.avg_poke_count_per_conv = stats["Average Amount of Pokes (Est.) Per conversation"];
        })
        .catch(error => console.error('Error:', error));
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },

    async fetchConvosDPO() {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/convos_dpo/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          },
          body: JSON.stringify({}), // Add any necessary request body data here
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Read the JSON response
        const data = await response.json();
        console.log('Fetched Conversations DPO:', data);

        // Trigger download
        this.downloadJSON(data, 'convos_dpo_data.json');
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      }
    },
    downloadJSON(data, filename) {
      const jsonString = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },


    async downloadConversations() {
      try {
          const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/convos/`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Token ${this.token}`,
              },
              body: JSON.stringify({
                  start: this.startDate + 'T00:00:00',
                  end: this.endDate + 'T23:59:59',
                  instruct_mode: false,
                  message_type: 'chats',
              }),
          });

          if (!response.ok) {
              throw new Error('Network response was not ok');
          }

          const jsonlData = await response.text(); // Get the response as text
          const blob = new Blob([jsonlData], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);

          // Create a link element and trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = 'conversation_data.jsonl';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error('Error:', error);
      }
  }

  },
};
</script>

<style>
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
}

h2, h3 {
  color: #0056b3;
}

.date-inputs {
  margin-bottom: 20px;
}

.date-inputs label {
  margin-right: 10px;
  font-weight: bold;
}

.date-inputs input[type="date"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-right: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #0066cc;
  color: white;
}

tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

a {
  color: #0056b3;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  th, td {
    text-align: right;
  }

  td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}

button {
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #004494;
}
</style>
