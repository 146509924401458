<template>
  <div class="pokes-page">
    <h1>Pokes</h1>

    <!-- Filter Section -->
    <div class="filter-section">
      <input
        v-model="filters.message_contains"
        placeholder="Filter by message content"
        @keyup.enter="applyFilters"
      />
      <input
        v-model="filters.profile_age"
        type="number"
        placeholder="Filter by Profile Age"
        @keyup.enter="applyFilters"
      />
      <input
        v-model="filters.profile_marital_status"
        placeholder="Filter by Marital Status"
        @keyup.enter="applyFilters"
      />
      
      <!-- Move checkboxes to the end -->
      <label>
        <input
          v-model="filters.profile_smoking"
          type="checkbox"
        /> Smoking
      </label>
      <label>
        <input
          v-model="filters.profile_piercing"
          type="checkbox"
        /> Piercing
      </label>
      <label>
        <input
          v-model="filters.profile_tattoo"
          type="checkbox"
        /> Tattoo
      </label>
      <label>
        <input
          v-model="filters.manually_added"
          type="checkbox"
        /> Manually Added
      </label>
      <label>
        <input
          v-model="filters.has_been_checked"
          type="checkbox"
        /> Has Been Checked
      </label>
      <label>
        <input
          v-model="filters.was_retarded"
          type="checkbox"
        /> Was Retarded
      </label>
      <label>
        <select v-model="filters.target_conversation">
          <option value="no_conversation">Fresh Pokes</option>
          <option value="no_response">Followup Pokes</option>
        </select>
        Type
      </label>

      <button @click="applyFilters">Apply Filters</button>
    </div>

    <!-- Table Section -->
    <div class="pokes-container">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Username</th>
            <th>Name</th>
            <th>Message</th>
            <th>Profile Age</th>
            <th>Profile Height</th>
            <th>Profile Body Type</th>
            <th>Marital Status</th>
            <th>Sent At</th>
            <th>Smoking</th>
            <th>Piercing</th>
            <th>Tattoo</th>
            <th>Quartile</th>
            <th>Was Retarded</th>
            <th>Reason for Retardation</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="poke in pokes" :key="poke.id">
            <td>{{ poke.id }}</td>
            <td>{{ poke.username }}</td>
            <td>{{ poke.email }}</td> <!-- Name from email -->
            <td>{{ poke.message }}</td> <!-- Show full message -->
            <td>{{ poke.profile_age }}</td>
            <td>{{ poke.profile_length || 'N/A' }}</td> <!-- New: Profile Length -->
            <td>{{ poke.profile_body_type || 'N/A' }}</td> <!-- New: Profile Body Type -->
            <td>{{ poke.profile_marital_status }}</td>
            <td>{{ formatDate(poke.send_at) }}</td>
            <td>{{ poke.profile_smoking ? 'Yes' : 'No' }}</td> <!-- Profile Smoking -->
            <td>{{ poke.profile_piercing ? 'Yes' : 'No' }}</td> <!-- Profile Piercing -->
            <td>{{ poke.profile_tattoo ? 'Yes' : 'No' }}</td> <!-- Profile Tattoo -->
            <td>{{ poke.quartile }}</td>
            <td>{{ poke.was_retarded ? 'Yes' : 'No' }}</td> <!-- Was retarded -->
            <td v-if="poke.was_retarded">{{ poke.reason_for_retardation || 'N/A' }}</td> <!-- Reason for retardation -->
            <td v-else>N/A</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination Section -->
    <div class="pagination">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 0">
        Previous
      </button>
      <span>Page {{ currentPage + 1 }}</span>
      <button @click="changePage(currentPage + 1)" :disabled="disableNext">
        Next
      </button>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      filters: {
        message_contains: '',
        profile_age: null,
        profile_marital_status: '', // New marital status filter
        profile_smoking: false,
        profile_piercing: false,
        profile_tattoo: false,
        performance: null,
        quartile: null,
        manually_added: false,
        has_been_checked: false,
        was_retarded: false,
        target_conversation: 'no_conversation',
        limit: 10, // Number of pokes to show per page
        offset: 0, // Offset for pagination
      },
      currentPage: 0,
      disableNext: false, // Disable Next button when no more pages
      pokes: [],
    };
  },
  methods: {
    // Helper function to build query parameters
    buildQueryParams(filters) {
      const params = new URLSearchParams();

      // Add filters only if they have values
      if (filters.message_contains !== '') {
        params.append('message_contains', filters.message_contains);
      }
      if (filters.profile_age !== null && filters.profile_age !== '') {
        params.append('profile_age', filters.profile_age);
      }
      if (filters.profile_marital_status !== '') {
        params.append('profile_marital_status', filters.profile_marital_status);
      }

      // Only add boolean filters if they are true (1)
      if (filters.profile_smoking) {
        params.append('profile_smoking', '1');
      }
      if (filters.profile_piercing) {
        params.append('profile_piercing', '1');
      }
      if (filters.profile_tattoo) {
        params.append('profile_tattoo', '1');
      }

      if (filters.performance !== null && filters.performance !== '') {
        params.append('performance', filters.performance);
      }
      if (filters.quartile !== null && filters.quartile !== '') {
        params.append('quartile', filters.quartile);
      }

      if (filters.target_conversation) {
        params.append('target_conversation', filters.target_conversation);
      }
      // Only add boolean filters if they are true (1)
      if (filters.manually_added) {
        params.append('manually_added', 'true');
      }
      if (filters.has_been_checked) {
        params.append('has_been_checked', 'true');
      }
      if (filters.was_retarded) {
        params.append('was_retarded', 'true');
      }

      // Always include the limit and offset
      params.append('limit', filters.limit);
      params.append('offset', filters.offset);

      return params.toString();
    },

    // Fetch pokes from the API
    async fetchPokes() {
      const queryString = this.buildQueryParams(this.filters);

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_BASE_URL}/api/users/pokes/?${queryString}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${token}`,
            },
          }
        );

        if (!response.ok) throw new Error('Failed to fetch pokes');

        const data = await response.json();

        this.pokes = data; // Use the returned list of pokes

        // Disable "Next" button if fewer than the limit were returned
        this.disableNext = this.pokes.length < this.filters.limit;
      } catch (error) {
        console.error('Error fetching pokes:', error);
      }
    },

    // Handle pagination
    changePage(page) {
      if (page < 0 || (this.disableNext && page > this.currentPage)) return;

      // Update the current page and adjust the offset for pagination
      this.currentPage = page;
      this.filters.offset = this.currentPage * this.filters.limit;
      this.fetchPokes(); // Fetch new pokes when changing the page
    },

    applyFilters() {
      this.filters.offset = 0; // Reset pagination when filters are applied
      this.currentPage = 0; // Reset to first page
      this.fetchPokes(); // Re-fetch with new filters
    },

    // Format date in a readable way
    formatDate(date) {
      const options = {
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        second: 'numeric',
        hour12: true, // If you prefer 12-hour format (AM/PM), remove for 24-hour
      };
      return new Date(date).toLocaleString(undefined, options);
    },

    // Get image URL (adjust based on your API's image handling)
    getImageUrl(filename) {
      return `${process.env.VUE_APP_API_BASE_URL}/images/${filename}`;
    },
  },
  mounted() {
    this.fetchPokes();
  },
};
</script>

<style scoped>
.pokes-page {
  padding: 20px;
}

.filter-section {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

.pokes-container table {
  width: 100%;
  border-collapse: collapse;
}

.pokes-container th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.pokes-container tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

.pokes-container tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

.profile-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #007bff; /* New background color: Blue */
  color: white; /* Text color: White */
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.pagination button:disabled {
  background-color: #cccccc; /* Disabled state: Light grey */
  cursor: not-allowed;
}

.pagination span {
  margin: 0 10px;
  font-size: 18px;
  font-weight: bold;
}
</style>
