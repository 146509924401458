<template>
  <div class="search-page">
    <div class="search-form">
      <h2>Search Pokes or Conversations</h2>
      <form @submit.prevent="performSearch(false)">
        <label for="requestType">Request Type:</label>
        <select v-model="search.request_type">
          <option value="p">Pokes</option>
          <option value="c">Conversations</option>
        </select>

        <label for="message">Message:</label>
        <input v-model="search.message" placeholder="Enter search message" />

        <label for="startTime">Start Time:</label>
        <input v-model="search.start_time" type="datetime-local" />

        <label for="endTime">End Time:</label>
        <input v-model="search.end_time" type="datetime-local" />

        <button type="submit" :disabled="isLoading">
          {{ isLoading ? 'Searching...' : 'Search' }}
        </button>
      </form>
    </div>

    <!-- Display Search Results -->
    <div v-if="searchResults.length > 0" class="search-results">
      <h3>Search Results ({{ totalCount }} found)</h3>
      <table>
        <thead>
          <tr>
            <th>Msg Type</th>
            <th>Username</th>
            <th>User Msg</th>
            <th>AI Generated Msg</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in searchResults" :key="result.id">
            <td>{{ result.request_type === 'P' ? 'Pokes' : 'Convos' }}</td>
            <td>{{ result.username }}</td>
            <td>
              <div v-if="isJSON(result.request)" class="small-font">
                <p><strong>Profile Name:</strong> {{ parseRequest(result.request).profile_name }}</p>
                <p><strong>Age:</strong> {{ parseRequest(result.request).profile_age }}</p>
                <p><strong>Height:</strong> {{ parseRequest(result.request).profile_height }} cm</p>
                <p><strong>Body Type:</strong> {{ parseRequest(result.request).profile_body_type }}</p>
                <p><strong>Eye Color:</strong> {{ parseRequest(result.request).profile_eye_color }}</p>
                <p><strong>Hair Color:</strong> {{ parseRequest(result.request).profile_hair_color }}</p>
                <p><strong>Smoking:</strong> {{ parseRequest(result.request).profile_smoking ? 'Yes' : 'No' }}</p>
                <p><strong>Emotion:</strong> {{ parseRequest(result.request).profile_emotion }}</p>
                <p><strong>Marital Status:</strong> {{ parseRequest(result.request).profile_marital_status }}</p>
                <p><strong>Target Has Photo:</strong> {{ parseRequest(result.request).target_has_photo ? 'Yes' : 'No' }}</p>
              </div>
              <div v-else>
                {{ result.request }}
              </div>
            </td>
            <td>
              <div v-if="isJSON(result.response)">
                {{ parseResponse(result.response).message }}
              </div>
              <div v-else>
                {{ result.response }}
              </div>
            </td>
            <td>{{ new Date(result.timestamp).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination Controls -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1 || isLoading">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages || isLoading">Next</button>
      </div>
    </div>

    <div v-else-if="searchPerformed && searchResults.length === 0">
      <p>No results found for the given search criteria.</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        message: '',
        request_type: 'p',  // Default to pokes
        start_time: '',
        end_time: '',
      },
      searchResults: [],
      totalCount: 0,
      searchPerformed: false,
      isLoading: false,
      token: null,
      autoRefreshInterval: null,
      currentPage: 1,
      limit: 10, // Number of results per page
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalCount / this.limit);
    }
  },
  methods: {
    getToken() {
      this.token = localStorage.getItem('token');
      if (!this.token) {
        console.error('No token found');
        return;
      }
    },
    async performSearch(shouldWeAutoRefresh = false) {
      this.isLoading = true;
      this.searchPerformed = true;
      this.searchResults = [];
      this.totalCount = 0;

      const offset = (this.currentPage - 1) * this.limit;
      const url = new URL(`${process.env.VUE_APP_API_BASE_URL}/api/transactions/`);
      url.searchParams.append('message', this.search.message);
      url.searchParams.append('request_type', this.search.request_type);
      url.searchParams.append('limit', this.limit);
      url.searchParams.append('offset', offset);

      if (this.search.start_time) {
        url.searchParams.append('start_time', new Date(this.search.start_time).toISOString());
      }
      if (this.search.end_time) {
        url.searchParams.append('end_time', new Date(this.search.end_time).toISOString());
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${this.token}`,
          }
        });
        if (response.ok) {
          const result = await response.json();
          this.totalCount = result.count;
          this.searchResults = result.transactions.reverse();

          if (!shouldWeAutoRefresh) {
            this.startAutoRefresh();
          }
        } else {
          console.error('Error fetching search results');
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    startAutoRefresh() {
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval);
      }

      this.autoRefreshInterval = setInterval(() => {
        console.log('Auto-refreshing the search...');
        this.performSearch(true);
      }, 300000); // 5 minutes in milliseconds
    },
    stopAutoRefresh() {
      if (this.autoRefreshInterval) {
        clearInterval(this.autoRefreshInterval);
        this.autoRefreshInterval = null;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.performSearch();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.performSearch();
      }
    },
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    },
    // Parse JSON to format request details
    parseRequest(request) {
      try {
        return JSON.parse(request);
      } catch (error) {
        console.error("Error parsing request JSON:", error);
        return {};
      }
    },
    parseResponse(response) {
      try {
        const parsed = JSON.parse(response);
        return parsed.message ? parsed : { message: response };
      } catch (error) {
        console.error("Error parsing response JSON:", error);
        return { message: response };
      }
    }
  },
  mounted() {
    this.getToken();
  },
  beforeUnmount() {
    this.stopAutoRefresh();
  },
};
</script>

<style scoped>
.search-form {
  margin: 20px;
}

.search-results table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.search-results th,
.search-results td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.search-results th {
  background-color: #000000;
}

.search-results tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

.search-results tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

.search-results td {
  word-wrap: break-word;
}

.search-results p {
  text-align: center;
  font-size: 1.2em;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.pagination span {
  margin: 0 10px;
}

.small-font {
  font-size: 0.7em;
}
</style>
