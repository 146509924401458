<template>
  <div class="login-container">
    <form @submit.prevent="login" class="login-form">
      <label for="username">Username:</label>
      <input type="text" id="username" v-model="username" required />

      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required />

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

      <button type="submit">Log in</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
  methods: {
    login() {
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/api/users/login/`, {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          if(response.data.is_manager) this.$router.push("/messageEditing");
          else if(response.data.is_supervisor) this.$router.push("/supervisorPage");
          else this.$router.push("/account");
        })
        .catch((error) => {
          this.errorMessage = "Error! Maybe check credentials?";
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  background-color: var(--background-dark);
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("@/assets/background.jpeg") no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--text-primary);
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: rgba(30, 30, 30, 0.8);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  width: 320px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.login-form label {
  font-weight: 500;
  margin-bottom: -5px;
  color: var(--secondary-color);
}

.login-form input {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background-color: var(--card-dark);
  color: var(--text-primary);
  font-size: 16px;
  transition: all 0.2s ease;
}

.login-form input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(98, 0, 234, 0.2);
  outline: none;
}

.login-form button {
  padding: 12px;
  border-radius: 6px;
  border: none;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  transition: all 0.2s ease;
}

.login-form button:hover {
  background-color: var(--primary-light);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.login-form button:active {
  transform: translateY(0);
  box-shadow: none;
}

.error-message {
  color: var(--error-color);
  word-wrap: break-word;
  margin: 5px 0;
  font-weight: 500;
  text-align: center;
  padding: 8px;
  background-color: rgba(207, 102, 121, 0.1);
  border-radius: 4px;
}
</style>
