<template>
  <div>
    <h2>Recent Messages</h2>
    <div class="date-inputs">
      <label for="startDate">Start Date:</label>
      <input type="date" id="startDate" v-model="startDate">

      <label for="endDate">End Date:</label>
      <input type="date" id="endDate" v-model="endDate">
    </div>
    <button @click="downloadCSV">Download CSV</button>
    <table v-if="messagesAndResponses.length > 0">
      <thead>
        <tr>
          <th class="medium-column">Timestamp</th>
          <th class="wide-column">Last Message</th>
          <th class="wide-column">AI Response</th>
          <th class="wide-column">Actual Response</th>
          <th class="wide-column">Similarity Rate</th>
          <th class="small-column">Geo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in filteredMessagesAndResponses" :key="index">
          <td class="medium-column">{{ formatTimestamp(item.timestamp) }}</td>
          <td class="wide-column">{{ item.last_message.content }}</td>
          <td class="wide-column" :style="{ backgroundColor: getBackgroundColor(item) }">{{ item.response }}</td>
          <td class="wide-column" :style="{ backgroundColor: getBackgroundColor(item) }">{{ item.actual_response }}</td>
          <td class="wide-column">{{ calculateSimilarity(item.response, item.actual_response) }}%</td>
          <td class="small-column">{{ item.geo }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      No messages found.
    </div>
  </div>
</template>
  
  <script>
  import { formatStartOfMonth, formatToday, formatDate } from '@/utils/dateUtils';
  import levenshtein from 'js-levenshtein';

  export default {
    name: 'RecentMessages',
    props: {
      username: String
    },
    data() {
      const storedStartDate = localStorage.getItem('startDate');
      const storedEndDate = localStorage.getItem('endDate');
      return {
        messagesAndResponses: [],
        startDate: storedStartDate || formatStartOfMonth(),
        endDate: storedEndDate || formatToday(),
      };
    },
    created() {
      this.getToken();
      this.fetchData();
    },
    methods: {
      getToken(){
        this.token = localStorage.getItem('token');
        if (!this.token) {
          console.error('No token found');
          return;
        }
      },
      async fetchData() {
        try {
            await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/users/recent-messages/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${this.token}`,
              },
              body: JSON.stringify({
                start: this.startDate + 'T00:00:00',
                end: this.endDate + 'T23:59:59',
                username: this.username
              }),
              }).then(response => response.json())
              .then(data => {
                this.messagesAndResponses = data.data;
              })
              .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
          }
      },
      getBackgroundColor(item) {
        if ((!item.response && item.response !== 0) && (!item.actual_response && item.actual_response !== 0)) {
          return '';
        }
        return item.response === item.actual_response ? 'green' : 'yellow';
      },
      formatTimestamp(timestamp) {
        return new Date(timestamp).toLocaleString();
      },
      calculateSimilarity(response, actualResponse) {
        if (!response || !actualResponse) {
          return 0;
        }
        const distance = levenshtein(response, actualResponse);
        const maxLength = Math.max(response.length, actualResponse.length);
        const similarity = ((maxLength - distance) / maxLength) * 100;
        return similarity.toFixed(2);
      },
      normalizeField(field) {
        if (field === null || field === undefined) {
          return '';
        }
        return field
          .replace(/"/g, '""') // Escape double quotes
          .replace(/’/g, "'")  // Replace typographical apostrophe with ASCII apostrophe
          .replace(/`/g, "'"); // Replace grave accent with ASCII apostrophe
      },
      downloadCSV() {
        const headers = ["Timestamp", "Last Message", "AI Response", "Actual Response", "Similarity Rate", "Geo"];
        const rows = this.messagesAndResponses.map(item => [
          `"${this.formatTimestamp(item.timestamp)}"`,
          `"${this.normalizeField(item.last_message.content)}"`,
          `"${this.normalizeField(item.response)}"`,
          `"${this.normalizeField(item.actual_response)}"`,
          `"${this.calculateSimilarity(item.response, item.actual_response)}%"`,
          `"${this.normalizeField(item.geo)}"`
        ]);

        const csvContent = [
          headers.map(header => `"${header}"`).join(","),
          ...rows.map(e => e.join(","))
        ].join("\n");

        const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "messages.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    watch: {
      startDate(newVal) {
        const formattedDate = formatDate(new Date(newVal));
        localStorage.setItem('startDate', formattedDate);
        this.fetchData();
      },
      endDate(newVal) {
        const formattedDate = formatDate(new Date(newVal));
        localStorage.setItem('endDate', formattedDate);
        this.fetchData();
      },
    },
    computed: {
      filteredMessagesAndResponses() {
        return this.messagesAndResponses.filter(item => item.response || item.last_message);
      }
    }
  };
  </script>
  
<style>
body {
  font-family: 'Roboto', sans-serif;
  color: #333;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
}

h2 {
  color: #0056b3;
  margin-bottom: 20px;
}

.date-inputs {
  margin-bottom: 20px;
}

.date-inputs label {
  margin-right: 10px;
  font-weight: bold;
}

.date-inputs input[type="date"] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  margin-right: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

th {
  background-color: #0066cc;
  color: white;
}

.small-column {
  width: 5%;
}

.medium-column{
  min-width: 90px;
}

.wide-column {
  width: 30%;
}

tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

td[style*="green"] {
  background-color: #4CAF50;
  color: white;
}

td[style*="yellow"] {
  background-color: #FFEB3B;
}

@media screen and (max-width: 600px) {
  table, thead, tbody, th, td, tr {
    display: block;
  }

  th, td {
    text-align: right;
  }

  td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}
</style>
