<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<style>
:root {
  --primary-color: #6200ea;
  --primary-light: #9d46ff;
  --primary-dark: #0a00b6;
  --secondary-color: #03dac6;
  --secondary-light: #66fff8;
  --secondary-dark: #00a896;
  --background-dark: #121212;
  --surface-dark: #1e1e1e;
  --card-dark: #2d2d2d;
  --text-primary: #ffffff;
  --text-secondary: #b0b0b0;
  --error-color: #cf6679;
  --success-color: #03dac6;
  --warning-color: #ffb74d;
  --border-color: #333333;
  --hover-color: #3a3a3a;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--background-dark);
  color: var(--text-primary);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.app-container {
  min-height: 100vh;
  background-color: var(--background-dark);
}

/* Global link styling */
a {
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: var(--secondary-light);
  text-decoration: underline;
}

/* Global button styling */
button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
  transition: all 0.2s ease;
  background-color: var(--card-dark);
  color: var(--text-primary);
}

button:hover {
  background-color: var(--hover-color);
}

/* Global input styling */
input, select, textarea {
  background-color: var(--card-dark);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
  border-radius: 4px;
  padding: 8px 12px;
}

input:focus, select:focus, textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

/* Global table styling */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: var(--surface-dark);
  margin: 15px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

th, td {
  border: 1px solid var(--border-color);
  padding: 12px;
  text-align: left;
  color: var(--text-primary);
}

th {
  background-color: var(--primary-dark);
  color: white;
  font-weight: 600;
}

tr:nth-child(even) {
  background-color: #2a2a2a; /* Darker grey */
}

tr:nth-child(odd) {
  background-color: #3a3a3a; /* Lighter grey */
}

tr:hover {
  background-color: var(--hover-color);
}

/* Custom scrollbar for the entire app */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--surface-dark);
}

::-webkit-scrollbar-thumb {
  background: var(--primary-dark);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* Utility classes */
.dark-bg {
  background-color: var(--background-dark);
}

.surface-bg {
  background-color: var(--surface-dark);
}

.card-bg {
  background-color: var(--card-dark);
}

.text-primary {
  color: var(--text-primary);
}

.text-secondary {
  color: var(--text-secondary);
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}
</style>
