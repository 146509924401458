<template>
  <div class="view-recent-page">
    <h1>Recent Messages</h1>
    <div class="message-container">
      <div v-if="loading" class="loading">
        <p>Loading messages...</p>
      </div>
      <div v-else-if="messages.length === 0" class="no-messages">
        <p>No recent messages found.</p>
      </div>
      <div v-else class="messages-list">
        <div v-for="message in messages" :key="message.id" class="message-card">
          <div class="message-header">
            <span class="username">{{ message.username }}</span>
            <span class="timestamp">{{ formatTimestamp(message.timestamp) }}</span>
          </div>
          <div class="message-content">
            {{ message.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewRecentPage',
  data() {
    return {
      messages: [],
      loading: true,
      socket: null,
      reconnectAttempts: 0,
      maxReconnectAttempts: 5,
      reconnectInterval: 3000, // 3 seconds
      maxMessages: 100, // Maximum number of messages to keep in the window
      isVisible: true, // Track if component is visible
    };
  },
  mounted() {
    this.connectWebSocket();
    
    // Add visibility change event listener to handle tab switching
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    
    // Add a custom event listener for tab changes within the app
    window.addEventListener('tabChanged', this.handleTabChange);
  },
  beforeUnmount() {
    this.disconnectWebSocket();
    
    // Remove event listeners
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('tabChanged', this.handleTabChange);
  },
  methods: {
    connectWebSocket() {
      this.loading = true;
      
      // Determine the WebSocket URL based on the current environment
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = process.env.VUE_APP_API_BASE_URL 
        ? process.env.VUE_APP_API_BASE_URL.replace(/^https?:\/\//, '')
        : window.location.host;
      
      const wsUrl = `${protocol}//${host}/ws/recent_messages/`;
      
      this.socket = new WebSocket(wsUrl);
      
      this.socket.onopen = () => {
        console.log('WebSocket connection established');
        this.reconnectAttempts = 0;
      };
      
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === 'recent_messages') {
          // For the first message, just set the messages directly
          if (this.loading) {
            this.messages = data.messages;
            this.loading = false;
          } else {
            // For subsequent messages, merge with existing messages
            // Create a map of existing messages by ID for quick lookup
            const existingMessagesMap = new Map(
              this.messages.map(msg => [msg.id, msg])
            );
            
            // Add new messages that don't already exist
            data.messages.forEach(newMsg => {
              if (!existingMessagesMap.has(newMsg.id)) {
                // Add to the beginning since messages are in reverse chronological order
                this.messages.unshift(newMsg);
              }
            });
            
            // Sort messages by timestamp (newest first)
            this.messages.sort((a, b) => 
              new Date(b.timestamp) - new Date(a.timestamp)
            );
            
            // Limit to maxMessages
            if (this.messages.length > this.maxMessages) {
              this.messages = this.messages.slice(0, this.maxMessages);
            }
          }
        }
      };
      
      this.socket.onclose = (event) => {
        console.log('WebSocket connection closed', event);
        
        // Only attempt to reconnect if the component is visible
        if (this.isVisible) {
          this.handleReconnect();
        } else {
          console.log('Component not visible, not attempting to reconnect after close');
        }
      };
      
      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.socket.close();
      };
    },
    
    disconnectWebSocket() {
      if (this.socket) {
        console.log('Disconnecting WebSocket');
        this.socket.close();
        this.socket = null;
      }
    },
    
    handleReconnect() {
      // Only attempt to reconnect if the component is visible
      if (!this.isVisible) {
        console.log('Component not visible, skipping reconnect');
        return;
      }
      
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);
        
        setTimeout(() => {
          if (this.isVisible) {
            this.connectWebSocket();
          } else {
            console.log('Component still not visible, skipping reconnect');
          }
        }, this.reconnectInterval);
      } else {
        console.error('Max reconnect attempts reached. Please refresh the page.');
      }
    },
    
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleString();
    },
    
    // Handle browser tab visibility changes
    handleVisibilityChange() {
      if (document.hidden) {
        // Browser tab is hidden
        console.log('Tab hidden, pausing WebSocket connection');
        this.isVisible = false;
        
        // Don't disconnect immediately, but pause updates to save resources
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          // We could send a message to the server to pause updates if needed
        }
      } else {
        // Browser tab is visible again
        console.log('Tab visible, resuming WebSocket connection');
        this.isVisible = true;
        
        // If socket was closed while tab was hidden, reconnect
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
          console.log('Reconnecting WebSocket after tab becomes visible');
          this.connectWebSocket();
        }
      }
    },
    
    // Handle app tab changes
    handleTabChange(event) {
      if (event.detail && event.detail.currentTab) {
        const isCurrentTab = event.detail.currentTab === 'viewRecent';
        console.log(`App tab changed, viewRecent tab is ${isCurrentTab ? 'active' : 'inactive'}`);
        
        this.isVisible = isCurrentTab;
        
        if (isCurrentTab) {
          // Our tab is now active
          if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            console.log('Reconnecting WebSocket after becoming active tab');
            this.connectWebSocket();
          }
        } else {
          // Our tab is now inactive, disconnect the WebSocket
          console.log('Tab inactive, disconnecting WebSocket');
          this.disconnectWebSocket();
        }
      }
    }
  }
};
</script>

<style scoped>
.view-recent-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  color: var(--primary-color);
  margin-bottom: 20px;
  font-size: 2rem;
}

.message-container {
  background-color: var(--surface-dark);
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-height: 400px;
}

.loading, .no-messages {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  color: var(--text-secondary);
  font-size: 1.2rem;
}

.messages-list {
  padding: 20px;
  max-height: 800px;
  overflow-y: auto;
}

.message-card {
  background-color: var(--card-dark);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-left: 4px solid var(--primary-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.message-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.username {
  font-weight: bold;
  color: var(--secondary-color);
}

.timestamp {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.message-content {
  color: var(--text-primary);
  line-height: 1.5;
  white-space: pre-wrap;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .view-recent-page {
    padding: 10px;
  }
  
  h1 {
    font-size: 1.5rem;
  }
  
  .message-card {
    padding: 12px;
  }
}
</style>
